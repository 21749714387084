import React, { memo } from "react";

import { Link } from "react-scroll";
import LangSwitchButton from "../LangSwitchButton";
import { Nav, Navbar as Bnavbar } from 'react-bootstrap';

function Navbar(props: { content: { home: string, about: string, crm: string, strategy: string, system: string, safety: string, managment: string, CustServeices: string, qahr: string, whyus: string, contactus: string, evalBtnText: string, loginBtnText: string }, langButtonLang: string, langButtonClick: CallableFunction }) {
    return (
        <Bnavbar className="bg-primary shadow fixed-top px-2 navbar-dark navbar-expand-xxl" collapseOnSelect expand="lg">
            <Bnavbar.Toggle />
            <Bnavbar.Brand>
                <LangSwitchButton lang={props.langButtonLang} onClick={props.langButtonClick} />
                <a href={"http://fcc.mawenwefawel.com/"} className="btn btn-warning rounded-0">{props.content.loginBtnText}</a>
                <a href={"http://fcc.mawenwefawel.com/evals/monthly"} className="btn btn-success rounded-0">{props.content.evalBtnText}</a>
            </Bnavbar.Brand>
            <Bnavbar.Collapse>
                <Nav className="ms-auto text-white text-center">
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            activeClass={"nav-active"}
                            as={Link} 
                            to="header">
                            {props.content.home}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="about">
                            {props.content.about}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="CRM">
                            {props.content.crm}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="strategy">
                            {props.content.strategy}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="System">
                            {props.content.system}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="Safety">
                            {props.content.safety}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="managment">
                            {props.content.managment}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="CustServeices">
                            {props.content.CustServeices}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="qahr">
                            {props.content.qahr}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="whyus">
                            {props.content.whyus}
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link spy={true}
                            smooth={true}
                            offset={-70}
                            duration={800} 
                            as={Link} 
                            activeClass={"nav-active"}
                            to="contactus">
                            {props.content.contactus}
                        </Nav.Link>
                    </Nav.Item>

                </Nav>
            </Bnavbar.Collapse>
        </Bnavbar>
    )
}
export default memo(Navbar)