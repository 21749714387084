import React, {memo} from "react";

function Image(props: {src: string, alt: string, className?: Array<string>})
{
    let className: Array<string> = [
        'img-fluid',
        ...props.className ?? []
    ];
    return <img src={props.src} alt={props.alt} className={className.join(' ')} />
}

export default memo(Image);