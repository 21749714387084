import React, {memo} from "react";
import Image from "./layout/Image";
import whyusImage from "../Image/whyus-img.webp"
function Whyus(props: {
    content: { heading: string, p1: string, p2: string },
    lang: string,
    dir: string
}) {

    return (
        <section id={"whyus"} className={"bg-danger"} lang={props.lang} dir={props.dir}>
            <div className="container-fluid p-5">
                <div className="row gap-1 justify-content-between align-items-center">
                    <div className="col-lg-5 text-white">
                        <article className={"lh-lg-lg lh-md-0 text-white lh-lg d-flex flex-column align-items-lg-start align-items-sm-center"}>
                            <h3 className={"fw-bold fs-1"}>{props.content.heading}</h3>
                            <p className={"mb-2 fs-5"}>{props.content.p1}</p>
                            <p className={"fs-5"}>{props.content.p2}</p>
                        </article>
                    </div>
                    <div className="col-lg-6">
                        <Image src={whyusImage} alt={"About Us"} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default memo(Whyus);