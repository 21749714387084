import React, { memo } from 'react';
import Image from './layout/Image';
import qahrImage from '../Image/qahr-img.webp'

function QaHr(props: { lang: string, dir: string, content: { heading1: string, heading2: string, heading3: string, p1: string, p2: string, p3: string } }) {

    return (
        <section lang={props.lang} dir={props.dir} className="bg-primary py-4" id="qahr">
            <div className="container-fluid px-5">
                <div className="row text-white align-items-center gap-1 justify-content-center">
                    <div className="col-lg-5">
                        <article className="d-flex flex-column justify-content-lg-start justify-content-sm-center">
                            <h3 className="fw-bold fs-1">{ props.content.heading1 }</h3>
                            <p className="fs-5">{ props.content.p1 }</p>
                        </article>
                        <article className="d-flex flex-column justify-content-lg-start justify-content-sm-center">
                            <h3 className="fw-bold fs-1">{ props.content.heading2 }</h3>
                            <p className="fs-5">{ props.content.p2 }</p>
                        </article>
                        <article className="d-flex flex-column justify-content-lg-start justify-content-sm-center">
                            <h3 className="fw-bold fs-1">{ props.content.heading3 }</h3>
                            <p className="fs-5">{ props.content.p3 }</p>
                        </article>
                    </div>

                    <div className="col-lg-6">
                        <Image src={qahrImage} alt={props.content.heading1} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default memo(QaHr);