import React, { memo } from "react";
import strategyImg from "../Image/strategy-img.webp"
import Image from "./layout/Image";

function Startegy(props: { lang: string, dir: string, content: { heading: string, p: string } }) {
    return (
        <section id={"strategy"} className={"bg-danger"} dir={props.dir} lang={props.lang}>
            <div className="container-fluid p-0 m-0 pt-lg-0 pt-sm-5 ">
                <div className="row p-0 m-0 gap-1 justify-content-between align-items-center">
                    <div className="col-lg-5 mx-lg-5">
                        <article className="text-white lh-lg d-flex flex-column align-items-lg-start align-items-sm-center">
                            <h3 className="fs-1 fw-bolder">{props.content.heading}</h3>
                            <p className="fs-5">{props.content.p}</p>
                        </article>
                    </div>

                    <div className="col-lg-6 p-0">
                        <Image src={strategyImg} alt={"Business Strategy"} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default memo(Startegy)