import React, { memo } from "react";
import bgImage from '../Image/sucsecret-bg.webp'

function SucSecret(props: {
    lang: string,
    dir: string,
    content: {
        text: string,
        ulTitle: string,
        liTitle1: string,
        liTitle2: string,
        liP1: string,
        liP2: string
    }
}) {
    return (
        <section id={"SucSecret"} style={{ backgroundImage: `url(${bgImage})`}} lang={props.lang} dir={props.dir}>
            <div className="container-fluid p-md-4 p-sm-0">
                <div className="bg-light-danger text-white px-5 py-3">
                    <article>
                        <p className="fs-5">{props.content.text}</p>
                        <br />
                        <p className="fs-5">{props.content.ulTitle}</p>
                        <ul>
                            <li>
                                <p className="fw-bold fs-5">{props.content.liTitle1}</p>
                                <p className="fs-5">{props.content.liP1}</p>
                            </li>

                            <li>
                                <p className="fw-bold fs-5">{props.content.liTitle2}</p>
                                <p className="fs-5">{props.content.liP2}</p>
                            </li>
                        </ul>
                    </article>
                </div>
            </div>
        </section>
    )
}
export default memo(SucSecret)