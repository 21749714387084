import React, { memo } from "react";

function Visitors(props: {
    lang: string,
    dir: string,
    content: {
        title: string,
        li1: string,
        li2: string,
        li3: string
    }
}) {
    return (
        <section id={"Visitors"} className="bg-primary" lang={props.lang} dir={props.dir}>
            <div className="container-fluid p-md-4 p-sm-0">
                <div className="text-white px-5 py-3">
                    <article>
                        <h3 className="fs-1 fw-bold">{props.content.title}</h3>
                        <ul>
                            <li>
                                <p className="fw-bold fs-5">{props.content.li1}</p>
                            </li>

                            <li>
                                <p className="fw-bold fs-5">{props.content.li2}</p>
                            </li>

                            <li>
                                <p className="fw-bold fs-5">{props.content.li3}</p>
                            </li>
                        </ul>
                    </article>
                </div>
            </div>
        </section>
    )
}
export default memo(Visitors)