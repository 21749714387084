import React, { memo } from 'react'
import bgImage from '../Image/header-bg.webp'
function Contactus(props: {
    content: { 
        h3: string, 
        h4: string,
        address: string,
        phone: string,
        facebook: string
    },
    lang: string,
    dir: string
}) {

    return (
        <footer id={"contactus"} style={{ backgroundImage: `url(${bgImage})`}} lang={props.lang}>
            <div className="h-100 container-fluid p-md-4 p-sm-0 ">
                <div className="h-100 text-center bg-light-primary position-relative d-flex flex-column justify-content-center align-items-center">

                    <h3 className="text-white fs-sm-3 fw-bolder">{props.content.h3}</h3>
                    <div id={"contactus-info"} className="position-absolute text-white" style={{top: "70%", left: "1%"}}>
                        <h4 className="fs-2 justify-self-start">
                            {props.content.h4}
                        </h4>
                        <p className="fs-5 d-flex align-items-center"><i className="bi bi-geo-alt-fill mx-2"></i>{props.content.address}</p>
                        <a href={`tel:${props.content.phone}`} className="fs-5 text-white mb-3 text-decoration-none d-flex align-items-center"><i className="bi bi-telephone-fill mx-2"></i>{props.content.phone}</a>
                        <a href={`https://www.facebook.com/MawenWeFawel/`} className="fs-5 text-white text-decoration-none d-flex align-items-center"><i className="bi bi-facebook mx-2"></i>{props.content.facebook}</a>
                    </div>

                </div>

            </div>
        </footer>
    )
}
export default memo(Contactus)