import React, { memo } from "react";
import bgImage from "../Image/system-bg.webp"

function System(props: {
    lang: string,
    dir: string,
    content: {
        heading1: string,
        p1: string,
        heading2: string,
        p2: string,
    }
}) {
    return (
        <section id={"System"} style={{ backgroundImage: `url(${bgImage})`}} lang={props.lang} dir={props.dir}>
            <div className="container-fluid p-md-4 p-sm-0">
                <div className="bg-light-primary text-white px-5 py-3">
                    <article>
                        <h3 className="fw-bold fs-1">{props.content.heading1}</h3>
                        <p className="fs-4">{props.content.p1}</p>

                    </article>
                    <article>
                        <h3 className="fw-bold fs-1">{props.content.heading2}</h3>
                        <p className="fs-4">{props.content.p2}</p>
                    </article>
                </div>
            </div>
        </section>
    )
}
export default memo(System)