import React, { useState } from 'react';

import './Sass/App.sass';
import Header from "./Components/Header";
import About from "./Components/About";
import Startegy from "./Components/Strategy";
import CRM from "./Components/CRM";
import System from "./Components/System";
import Safety from "./Components/Safety";
import Navbar from './Components/layout/Navbar';
import Management from './Components/Management';
import QAandHR from './Components/QAandHR';
import CustServices from './Components/CustServices';
import Whyus from './Components/Whyus';
import SucSecret from './Components/SucSecret';
import Visitors from './Components/Visitors';
import Contactus from './Components/Footer';

let userLang = navigator.language;


function App() {
    let content = {
        en: {
            current: "en",
            dir: "ltr",
            header: {
                h1: "Gas Station Management",
                p: "Distinguished & Innovative Management System"
            },

            about: {
                heading: "About Us",
                p1: `Mawen w Fawel is a company specialized in gas
                    station management. We have a distinguished
                    management regulations that are built based on
                    advanced scientific system and an innovative
                    operating system. We are currently working on
                    managing Chillout gas stations in 6th of October
                    and Suez Road.`,
                p2: `We are very proud of what we have provided to
                    raise the efficiency of the service with
                    professionalism and perfection so far. We always
                    strive to develop our work module to achieve
                    better customer service and greater sales growth
                    rate.`
            },
            strategy: {
                heading: "Business Strategy",
                p: `Mawen w Fawel is a unique gas sta-
                    tions’ management company. We
                    created a distinct business strategy
                    that we follow to achieve success.
                    Our advanced operating system dif-
                    fers from others. We can summarize
                    the strategy through the following
                    points.`
            },
            crm: {
                heading1: "Customer Care",
                heading2: "Employee Management",
                p1: `Customer satisfaction with the level of service is one of our top priorities. Hence,
                    we were keen to establish a special department for customer care that
                    periodically measures the level of customer satisfaction and works to raise
                    efficiency based on customer opinions. It also seeks to solve customers'
                    problems before they leave the station and as soon as possible.`,
                p2: `Customer satisfaction with the level of service is one of our top priorities. Hence,
                    we were keen to establish a special department for customer care that
                    periodically measures the level of customer satisfaction and works to raise
                    efficiency based on customer opinions. It also seeks to solve customers'
                    problems before they leave the station and as soon as possible.`,
            },
            system: {
                heading1: "Privileges for Employees",
                heading2: `Lorem, ipsum dolor.`,
                p1: `Our employees are the basis of our success. Thus, we provide
                    employees with a fixed monthly salary and continuous training.
                    Additionally, we are currently studying the provision of a social and
                    health insurance system for them.`,
                p2: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae quis veniam necessitatibus illo adipisci quaerat repudiandae ullam totam, ab, magni delectus magnam repellat nobis illum?`,
            },
            safety: {
                heading1: "Safety Regulations",
                heading2: `Quality Control`,
                p1: `In addition to maintaining safety procedures inside the stations, we
                    provide trainings to all the station workers on firefighting in order to
                    quickly act in crises. The company also has a special department for
                    occupational safety and health.`,
                p2: `In order to maintain the success of our system, we established a quality
                    control department to consistently monitor the quality of the service
                    provided.`,
            },
            navbar: {
                home: "Home",
                about: "About Us",
                crm: "Customer Care",
                strategy: "Business strategy",
                system: "Privileges",
                safety: "Safety & Quality",
                managment: "Management and Services",
                CustServeices: "Customer Service",
                qahr: "QA & HR",
                whyus: "Why us",
                contactus: "Contact us",
                loginBtnText: "Login",
                evalBtnText: "Evaluations"

            },
            management: {
                heading1: "Our Management and Services",
                heading2: "Privileges for Customers",
                p1: `To achieve the pillars of our business strate-
                    gy at the gas stations affiliated with Mawen
                    w Fawel administration, we have added a
                    number of services within the stations and
                    established specialized departments.`,
                p2: `We provide promotional activities for
                    customers to increase sales through several
                    innovative ideas inside the stations.`,
            },
            CustService: {
                heading1: "Customer Services",
                heading2: `Privileges for Customers`,
                p1: `In addition to maintaining safety procedures inside the stations, we
                    provide trainings to all the station workers on firefighting in order to
                    quickly act in crises. The company also has a special department for
                    occupational safety and health.`,
                p2: `In order to maintain the success of our system, we established a quality
                    control department to consistently monitor the quality of the service
                    provided.`,
            },
            qahr: {
                heading1: "Quality Assurance Department",
                heading2: "HR & Development Department",
                heading3: "Female Employees",
                p1: `Our stations have a specialized
                    department for service quality control
                    and monitoring.`,
                p2: `We have a specialized department for
                    employee training and development
                    for a better customer service`,
                p3: `We hire female employees in addition
                    to male employees.`,
            },

            whyus: {
                heading: "Why Us ?",
                p1: `It didn't take us too long to expand.
                    We started with one gas station then
                    others followed sequentially. We
                    succeeded in serving more than
                    3000 clients daily!`,
                p2: `Additionally, we were awarded “the
                    Best Gas Station in Chillout”. Forty
                    gas stations were contesting for this
                    title and we were able to win the
                    competition.`,
                
            },
            sucSecret: {
                text: `The secret of our success is focusing on the provision of high-quality
                    service to gain more foot traffic which in turn increases the total sales of
                    any gas station.`,
                ulTitle: `We work on two main axes:`,
                liTitle1: `Innovation`,
                liTitle2: `Mastery`,
                liP1: `We have an innovative business model that allows us to bring out new
                    business ideas. Today, our gas stations have female employees and a
                    customer care center!. That’s something new that we do not see in any
                    gas station in Egypt.`,
                liP2: `Mastering every stage in the operations is a key to the provision of a better
                    & faster service. This what makes us the fastest tank filling service in
                    Egypt!`,
            },
            visitors: {
                title: `Our Visitors`,
                li1: `Chillout gas station, Cityscape
                    Square, 6th October, Giza.`,
                li2: `Chillout gas station, Vodafone
                    Square next to Al Baraka Bank, 6th
                    October, Giza.`,
                li3: `Chillout gas station, Cairo- Suez
                    Road.`
            },
            contactus: {
                h3: `BETTER STATION 
                    EFFICIENCY STARTS WITH US`,
                h4: `CONTACT US`,
                address: `Cityscape Square, 6th October, Giza`,
                phone: `+201016614062`,
                facebook: `MawenWeFawel`
            }
        },
        ar: {
            current: "ar",
            dir: "rtl",
            header: {
                h1: "لإدارة محطات الوقود",
                p: "نظام إداري علمي، متميز ومبتكر"
            },

            about: {
                heading: "من نحن ؟",
                p1: `‫ﻣﻮن وﻓﻮل ‫ﻫﻲ‬ ‫ﺷﺮﻛﺔ‬ ‫ﻣﺘﺨﺼﺼﺔ‬ ‫ﻓﻰ‬ ‫إدارة‬ ‫ﻣﺤﻄﺎت‬
                    ‫اﻟﻮﻗﻮد‬‫ﺗﻌﻤﻞ‬ ‫ﻣﻦ‬ ‫ﺧﻼل‬ ‫ﻣﻨﻈﻮﻣﺔ‬ ‫إدارﻳﺔ‬ ‫ﻣﺘﻤﻴﺰة‬ ‫ﺗﻌﻤﻞ‬
                    ‫ﺑﻨﻈﺎم‬‫ﻋﻠﻤﻲ‬ ‫ﻣﺘﻄﻮر‬ ‫وﻧﻈﺎم‬ ‫ﺗﺸﻐﻴﻠﻲ‬ ‫ﻣﺒﺘﻜﺮ‪.‬ﻧﻌﻤﻞ‬
                    ‫ﺣﺎﻟﻴﺎ‬‫ﻋﻠﻰ‬ ‫إدارة‬ ‫ﻣﺤﻄﺎت‬ ‫اﻟﻮﻗﻮد‬ ‫اﻟﺨﺎﺻﺔ‬ ‫ﺑﺸﻴﻞ‬ ‫أوت‬ ‫ﻓﻰ‬
                    ‫‪.‬أﻛﺘﻮﺑﺮ‬‫وﻃﺮﻳﻖ‬ ‫اﻟﺴﻮﻳﺲ‬`,
                p2: `‫ﻓﺨﻮرون‬‫ﺟﺪا‬ ‫ﺑﻤﺎ‬ ‫ﻗﺪﻣﻨﺎه‬ ‫ﻣﻦ‬ ‫رﻓﻊ‬ ‫ﻛﻔﺎءة‬ ‫اﻟﻤﺤﻄﺎت‬
                    ‫اﻟﺨﺪﻣﻴﺔ‬‫ﺑﻜﻞ‬ ‫اﺣﺘﺮاﻓﻴﺔ‬ ‫وإﺗﻘﺎن‪.‬‬ ‫وﻧﺴﻌﻰ‬ ‫داﺋﻤﺎ‬ ‫ﻟﺘﻄﻮﻳﺮ‬
                    ‫ﻣﻨﻈﻮﻣﺔ‬‫اﻟﻌﻤﻞ‬ ‫ﻟﺘﺤﻘﻴﻖ‬ ‫أﻓﻀﻞ‬ ‫ﺧﺪﻣﺔ‬ ‫ﻟﻠﻌﻤﻼء‬ ‫وأﻛﺒﺮ‬
                    ‫‪.‬ﻣﻌﺪل‬‫ﻧﻤﻮ‬ ‫ﻟﻠﻤﺒﻴﻌﺎت‬`

            },
            strategy: {
                heading: "‫إﺳﺘﺮاﺗﻴﺠﻴﺔ‬‫اﻟﻌﻤﻞ‬",
                p: `‫ﻧﺘﻤﻴﺰ‬‫ﺑﺈﺳﺘﺮاﺗﻴﺠﻴﺔ‬ ‫ﻋﻤﻞ‬ ‫ﻣﺘﻤﻴﺰة‬ ‫وﻣﻨﻈﻮﻣﺔ‬
                    ‫ﺗﺸﻐﻴﻠﻴﺔ‬‫ﻣﺘﻄﻮرة‬ ‫ﺗﺨﺘﻠﻒ‬ ‫ﻋﻦ‬ ‫ﻏﻴﺮﻫﺎ‪.‬‬
                    ‫اﺳﺘﺮاﺗﻴﺠﻴﺔ‬‫ﻋﻤﻠﻨﺎ‬ ‫ﺗﺘﺒﻊ‬ ‫أﺳﻠﻮب‬ ‫ﻋﻠﻤﻲ‬ ‫ﻟﺘﺤﻘﻴﻖ‬
                    ‫أﻓﻀﻞ‬‫ﺧﺪﻣﺔ‬ ‫ﻟﻠﻌﻤﻼء‬ ‫وأﻛﺒﺮ‬ ‫ﻣﻌﺪل‬ ‫ﻧﻤﻮ‬
                    ‫ﻟﻠﻤﺒﻴﻌﺎت‪.‬‬‫ﻳﻤﻜﻦ‬ ‫ﺗﻠﺨﻴﺺ‬ ‫ﻣﺤﺎور‬ ‫اﻟﺘﻤﻴﺰ‬ ‫ﻣﻦ‬ ‫ﺧﻼل‬
                    ‫‪.‬اﻟﻨﻘﺎط‬‫اﻵﺗﻴﺔ‬`
            },
            crm: {
                heading1: "رضاء العملاء",
                heading2: "",
                p1: "",
                p2: "",
            },
            system: {
                heading1: "Privileges for Employees",
                heading2: `Lorem, ipsum dolor.`,
                p1: `Our employees are the basis of our success. Thus, we provide
                    employees with a fixed monthly salary and continuous training.
                    Additionally, we are currently studying the provision of a social and
                    health insurance system for them.`,
                p2: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae quis veniam necessitatibus illo adipisci quaerat repudiandae ullam totam, ab, magni delectus magnam repellat nobis illum?`,
            },
            safety: {
                heading1: "Safety Regulations",
                heading2: `Quality Control`,
                p1: `In addition to maintaining safety procedures inside the stations, we
                    provide trainings to all the station workers on firefighting in order to
                    quickly act in crises. The company also has a special department for
                    occupational safety and health.`,
                p2: `In order to maintain the success of our system, we established a quality
                    control department to consistently monitor the quality of the service
                    provided.`,
            },
            navbar: {
                home: "الرئيسية",
                about: "من نحن",
                crm: "رضاء العملاء",
                strategy: "استراجية العمل",
                system: "الصلاحيات",
                safety: "الامان والجودة",
                managment: "الإدارة والخدمات",
                CustServeices: "خدمة العملاء",
                qahr: "الجودة والموارد البشرية",
                whyus: "لما نحن",
                contactus: "تواصل معنا",
                loginBtnText: "تسجيل دخول",
                evalBtnText: "التقييم"
            },

            management: {
                heading1: "Our Management and Services",
                heading2: "Privileges for Customers",
                p1: `To achieve the pillars of our business strate-
                    gy at the gas stations affiliated with Mawen
                    w Fawel administration, we have added a
                    number of services within the stations and
                    established specialized departments.`,
                p2: `We provide promotional activities for
                    customers to increase sales through several
                    innovative ideas inside the stations.`,
            },
            
            CustService: {
                heading1: "Customer Services",
                heading2: `Privileges for Customers`,
                p1: `In addition to maintaining safety procedures inside the stations, we
                    provide trainings to all the station workers on firefighting in order to
                    quickly act in crises. The company also has a special department for
                    occupational safety and health.`,
                p2: `In order to maintain the success of our system, we established a quality
                    control department to consistently monitor the quality of the service
                    provided.`,
            },
            qahr: {
                heading1: "Quality Assurance Department",
                heading2: "HR & Development Department",
                heading3: "Female Employees",
                p1: `Our stations have a specialized
                    department for service quality control
                    and monitoring.`,
                p2: `We have a specialized department for
                    employee training and development
                    for a better customer service`,
                p3: `We hire female employees in addition
                    to male employees.`,
            },
            whyus: {
                heading: "Why Us ?",
                p1: `It didn't take us too long to expand.
                    We started with one gas station then
                    others followed sequentially. We
                    succeeded in serving more than
                    3000 clients daily!`,
                p2: `Additionally, we were awarded “the
                    Best Gas Station in Chillout”. Forty
                    gas stations were contesting for this
                    title and we were able to win the
                    competition.`,
            },
            sucSecret: {
                text: `The secret of our success is focusing on the provision of high-quality
                    service to gain more foot traffic which in turn increases the total sales of
                    any gas station.`,
                ulTitle: `We work on two main axes:`,
                liTitle1: `Innovation`,
                liTitle2: `Mastery`,
                liP1: `We have an innovative business model that allows us to bring out new
                    business ideas. Today, our gas stations have female employees and a
                    customer care center!. That’s something new that we do not see in any
                    gas station in Egypt.`,
                liP2: `Mastering every stage in the operations is a key to the provision of a better
                    & faster service. This what makes us the fastest tank filling service in
                    Egypt!`,
            },
            visitors: {
                title: `Our Visitors`,
                li1: `Chillout gas station, Cityscape
                    Square, 6th October, Giza.`,
                li2: `Chillout gas station, Vodafone
                    Square next to Al Baraka Bank, 6th
                    October, Giza.`,
                li3: `Chillout gas station, Cairo- Suez
                    Road.`
            },
            contactus: {
                h3: `BETTER STATION 
                    EFFICIENCY STARTS WITH US`,
                h4: `CONTACT US`,
                address: `Cityscape Square, 6th October, Giza`,
                phone: `+201016614062`,
                facebook: `MawenWeFawel`
            }
        }
    };
    const [lang, setLang] = useState((userLang.startsWith('en') ? content.en : content.ar));


    return ( 
        <>
            <Navbar content={lang.navbar} langButtonLang={lang.current} langButtonClick={() => setLang((lang.current === "en") ? content.ar : content.en)} />
            <Header lang={lang.current} dir={lang.dir} content={lang.header} />
            <About lang={lang.current} dir={lang.dir} content={lang.about} />
            <CRM lang={lang.current} dir={lang.dir} content={lang.crm} />
            <Startegy lang={lang.current} dir={lang.dir} content={lang.strategy} />
            <System lang={lang.current} dir={lang.dir} content={lang.system} />
            <Safety lang={lang.current} dir={lang.dir} content={lang.safety} />
            <Management lang={lang.current} dir={lang.dir} content={lang.management}/>
            <CustServices lang={lang.current} dir={lang.dir} content={lang.CustService} />
            <QAandHR lang={lang.current} dir={lang.dir} content={lang.qahr}/>
            <Whyus lang={lang.current} dir={lang.dir} content={lang.whyus} />
            <SucSecret lang={lang.current} dir={lang.dir} content={lang.sucSecret} />
            <Visitors lang={lang.current} dir={lang.dir} content={lang.visitors} />
            <Contactus lang={lang.current} dir={lang.dir} content={lang.contactus} />
            
        </>
    );
}

export default App;
