import React, {memo} from 'react'
import Logo from "./layout/Logo";
import bgImage from "../Image/header-bg.webp"

function Header(props: {
    content: { h1: string, p: string },
    lang: string,
    dir: string
}) {

    return (
        <header id={"header"} style={{ backgroundImage: `url(${bgImage})`}} lang={props.lang}>
            <div className="h-100 container-fluid p-md-4 p-sm-0">
                <div className="h-100 text-center bg-light-primary d-flex flex-column justify-content-center align-items-center">
                    <Logo />
                    <h1 className="text-danger fs-sm-3 fw-bolder">{props.content.h1}</h1>
                    <p className="fs-3 fs-sm-4  text-white">{props.content.p}</p>
                </div>
            </div>
        </header>
    )
}
export default memo(Header)